import React, { useState } from "react"
import { Link as GLink } from "gatsby"
// import { GatsbyImage as Img } from 'gatsby-plugin-image'
import Sticky from "react-sticky-el"
import { Container, Box, Flex, css, Link, Text } from "theme-ui"
// import Reveal from '@components/Reveal'
import Drawer from "@components/Drawer"
// import getImage from '@components/utils/getImage'
import Reveal from "@components/Reveal"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  wrapper: {
    position: `relative`,
    zIndex: 10,
    ".nav-container": {
      bg: `headerBg`,
      position: `fixed`,
      transition: `all 250ms ease-in`,
      overflow: `hidden`,
      py: 4,
    },
    ".nav-sticky .nav-container": {
      bg: `white`,
      boxShadow: `0 0 25px rgba(140,152,164,.25)`,
      py: [3, 3, 2],
    },
    //Make buttons in header smaller
    ".button-group-button": {
      minWidth: 120,
      fontSize: 1,
      px: 3,
      py: 1,
    },
  },
  header: {
    justifyContent: `space-between`,
    alignItems: `center`,
    // height: [`6rem`, `7rem`], //prevent layout shift
  },
  logoContainer: {
    flexShrink: 0,
    mr: [null, null, 3, 5],
    maxWidth: ["80%", "100%"],
  },
  desktopMenu: {
    display: [`none`, null, `block`],
    minWidth: `auto`,
    flexGrow: 1,
  },
  mobileMenu: {
    display: [`block`, null, `none`],
  },
}

const Header = () => {
  //Close mobile menu after clicking menu items
  const [location, setLocation] = useState()
  const handleClick = e => setLocation(e.target.href)

  return (
    <>
      <Sticky
        enabled="true"
        stickyClassName="nav-sticky"
        css={css(styles.wrapper)}
      >
        <Container variant="full" className="nav-container">
          <Container px="4">
            <Flex sx={styles.header}>
              <Box sx={styles.logoContainer}>
                <GLink to="/">
                  <StaticImage
                    placeholder="none"
                    src="../../../static/logo.png"
                  />
                </GLink>
              </Box>
              <>
                <Box sx={styles.desktopMenu}>
                  <Reveal effect="fadeInDown">
                    <Flex
                      sx={{
                        alignItems: `center`,
                        justifyContent: `flex-end`,
                      }}
                      m="-3"
                    >
                      <Link
                        variant="mute"
                        sx={{ fontWeight: "600" }}
                        as={GLink}
                        to="/"
                      >
                        <Box m="3">HOME</Box>
                      </Link>
                      <Link
                        variant="mute"
                        sx={{ fontWeight: "600" }}
                        as={GLink}
                        to="/shop"
                      >
                        <Box m="3">PRODUCTS</Box>
                      </Link>
                      <a
                        variant="mute"
                        style={{
                          fontWeight: "600",
                          textDecoration: "unset",
                          color: "white",
                        }}
                        href="mailto: vintagebikestore@gmail.com"
                      >
                        <Box m="3" style={{
                              background: "#0062b1",
                              borderRadius: "25px",
                              padding: ".25rem 1rem",
                        }}>vintagebikestore@gmail.com</Box>
                      </a>
                    </Flex>
                  </Reveal>
                </Box>
                <Box sx={styles.mobileMenu}>
                  <Drawer
                    locationState={location}
                    buttonStyle={{ svg: { size: 32 } }}
                  >
                    <Box sx={{ fontSize: 3 }} m="3">
                      <Reveal effect="fadeInDown">
                        <Flex
                          sx={{
                            alignItems: `center`,
                            flexDirection: "column",
                            justifyContent: `flex-end`,
                          }}
                          m="-3"
                        >
                          <Link
                            variant="mute"
                            sx={{ fontWeight: "600" }}
                            as={GLink}
                            to="/"
                          >
                            <Box m="3">HOME</Box>
                          </Link>
                          <Link
                            variant="mute"
                            sx={{ fontWeight: "600" }}
                            as={GLink}
                            to="/shop"
                          >
                            <Box m="3">PRODUCTS</Box>
                          </Link>
                          <a
                            variant="mute"
                            style={{
                              fontWeight: "600",
                              textDecoration: "unset",
                              color: "white",
                            }}
                            href="mailto: vintagebikestore@gmail.com"
                          >
                            <Box m="3" style={{
                              background: "#0062b1",
                              borderRadius: "25px",
                              padding: ".25rem 1rem",
                        }}>vintagebikestore@gmail.com</Box>
                          </a>
                        </Flex>
                      </Reveal>
                    </Box>
                  </Drawer>
                </Box>
              </>
            </Flex>
          </Container>
        </Container>
      </Sticky>
    </>
  )
}

Header.defaultProps = {
  menuJustify: `flex-end`,
}

export default Header
