import React from "react"
import { Global } from "@emotion/react"
import { Flex, Box, css, Link } from "theme-ui"
import pageContextProvider from "@helpers/pageContextProvider"
import Header from "@layout/Header/Header"

const Layout = ({ children, pageContext, location = {} }) => {
  return (
    <pageContextProvider.Provider value={{ pageContext, location }}>
      <Flex variant="layout.layout">
        <Global styles={css(theme => theme.global)} />
        <Header/>
        <Box variant="layout.body">{children}</Box>
        <Box
  sx={{
    bg: "black",
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    p: 2,
    variant: 'styles.footer',
  }}>
  <Link to="/" sx={{ variant: 'styles.navlink', p: 2 }}>
    © {new Date().getFullYear()} VintageBikeStore, All Rights Reserved.
  </Link>
</Box>
      </Flex>
    </pageContextProvider.Provider>
  )
}

export default Layout
