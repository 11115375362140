import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { Box, IconButton } from "theme-ui"
import { FaBars, FaTimes } from "react-icons/fa"
import "./styles.css"

const styles = {
  panelContent: {
    height: `100%`,
    zIndex: 2,
    bg: `contentBg`,
    overflowY: `scroll`,
    p: 4,
  },
  openButton: {
    // display: ['', '', 'none'], //to avoid ssr rehydration issue
    verticalAlign: `middle`,
    position: "absolute",
    display: "inline-flex",
    p: "10px",
    mt: "-2rem",
    right: "1.5rem",
  },
  closeButton: {
    position: `fixed`,
    zIndex: 99999,
    left: `-30%`,
    top: 4,
    color: `white`,
  },
}

const Drawer = ({ children, locationState, buttonStyle }) => {
  const [open, setOpen] = useState()
  const handleClick = () => setOpen(!open)

  useEffect(() => {
    open && locationState && handleClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState])

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ ...styles.openButton, ...buttonStyle }}
        aria-label="Open Drawer"
      >
        <FaBars />
      </IconButton>
      <Modal
        isOpen={open}
        onRequestClose={handleClick}
        closeTimeoutMS={300}
        contentLabel="Drawer"
        className="DrawerPortalContent"
        overlayClassName="DrawerPortalOverlay"
      >
        <Box sx={styles.panelContent}>{children}</Box>
        <IconButton
          onClick={handleClick}
          sx={{ ...styles.closeButton, ...buttonStyle }}
          aria-label="Close Drawer"
        >
          <FaTimes />
        </IconButton>
      </Modal>
    </>
  )
}

export default Drawer
